<template>
  <div class="container" ref="homeContainer">
    <section class="hero" id="hero-section">
      <div class="hero__headline">
        <h1>{{ $t("hiLetsTalkLbl") }}</h1>
      </div>
    </section>
    <div class="hero__animation">
      <Lines />
    </div>
    <section class="hero" id="hero-section-2">
      <div class="hero__headline_2">
        <h1>{{ $t("contactUsByLbl") }}</h1>
      </div>
      <div class="hero__headline_phoneNo">
        <h1>{{ $t("contactPhoneNumber") }}</h1>
      </div>
      <div class="hero__headline_email">
        <h1>{{ $t("contactEmailSpinnov") }}</h1>
      </div>
    </section>
    <section class="formSection">
      <div class="formSection__headline_form">
        <h1>{{ $t("orByFormLbl") }}</h1>
        <form class="vue-form" @submit.prevent="submit">
          <fieldset class="fieldset">
            <div>
              <label class="label" for="name">{{ $t("yourNameLblForm") }}</label>
              <input type="text" name="name" id="name" required="" v-model="name" />
            </div>
            <div>
              <label
                class="label"
                for="email"
                :class="{ emailLblErrorClass: !email.valid }"
                >{{ $t("yourEmailAddressFormLbl") }}</label
              >
              <input
                type="email"
                name="email"
                id="email"
                required=""
                :class="{ email, error: !email.valid }"
                v-model="email.value"
              />
            </div>

            <div style="margin-bottom: 17px">
              <label class="label" for="textarea">{{ $t("textArealblForm") }}</label>
              <textarea
                class="message"
                name="textarea"
                id="textarea"
                required=""
                v-model="message.text"
                :maxlength="message.maxlength"
              ></textarea>
              <span class="counter"
                >{{ message.text.length }} / {{ message.maxlength }}</span
              >
            </div>
            <div style="margin-top: 0px; margin-bottom: 0px" v-show="!email.valid">
              <p class="weNeedYourEmail">
                {{ $t("weNeedYourEmailAddrToLbl") }}
              </p>
            </div>
            <div style="margin-top: 0px; margin-bottom: 0px" v-show="err">
              <p class="weNeedYourEmail">
                {{ $t("errSubmittingContact") }}
              </p>
            </div>
            <vue-recaptcha
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              size="invisible"
              sitekey="6Lej2owaAAAAALmhhOl0ctOAOkzU82v3vv1SQTNS"
            >
            </vue-recaptcha>
            <div style="margin-top: 17px">
              <input
                type="submit"
                :value="submitted ? $t('thankYouFor') : $t('sendMassageBtn')"
                :disabled="submitted"
                :class="submitted ? 'submittedClass' : ''"
              />
            </div>
          </fieldset>
        </form>
      </div>
    </section>
    <section class="offersSection" id="hero-section-3">
      <div class="offersSection__headline_likeUs">
        <h1>{{ $t("doYouLikeUsLbl") }}</h1>
      </div>
      <div class="offersSection__headline_Team">
        <h1>{{ $t("joinOurTeamLbl") }}</h1>
      </div>
      <div class="offersSection__see-all">
        <button
          class="btn-primary btn-primary-right-lg"
          @click="$router.replace('/jobs')"
        >
          <span></span>
          <p>{{ $t("checkOutOffersLbl") }}</p>
          <div></div>
        </button>
      </div>
    </section>
    <div class="footer-section">
      <Footer />
    </div>
  </div>
</template>

<script>
import Lines from "../components/Lines";
import Footer from "../components/Footer";
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
import EventBus from "../event-bus";
var emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export default {
  data() {
    return {
      name: "",
      email: {
        value: "",
        valid: true,
      },
      message: {
        text: ``,
        maxlength: 500,
      },
      submitted: false,
      err: false,
    };
  },
  watch: {
    // watching nested property
    "email.value": function (value) {
      this.validate("email", value);
    },
  },
  mounted() {
    if (this.$refs.homeContainer) {
      this.$refs.homeContainer.addEventListener("scroll", this.onScrollHandler);
    }
  },
  created() {
    let ckeditor = document.createElement("script");
    ckeditor.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    document.head.appendChild(ckeditor);
  },
  methods: {
    onScrollHandler(event) {
      if (this.resizedSmallerScreen) {
        window.scrollTo(0, 1);
      }
      let x = (event.target.scrollTop / 10) % Math.PI;
      EventBus.$emit("pageScrolled", { theta: x });
    },
    // submit form handler
    submit() {
      this.$refs.recaptcha.execute();
    },
    async onCaptchaVerified(recaptchaToken) {
      this.$refs.recaptcha.reset();
      // ! handling CV file format
      try {
        await axios.post(`https://api.spinnov.com/api/contact`, {
          name: this.name,
          email: this.email.value,
          message: this.message.text,
          recaptchaToken: recaptchaToken,
        });
        this.submitted = true;
      } catch (e) {
        this.err = true;

        this.submitted = false;
      }
    },
    onCaptchaExpired: function () {
      this.$refs.recaptcha.reset();
    },
    // validate by type and value
    validate(type, value) {
      if (type === "email") {
        this.email.valid = this.isEmail(value) ? true : false;
      }
    },
    // check for valid email adress
    isEmail(value) {
      return emailRegExp.test(value);
    },
  },
  components: {
    Lines,
    Footer,
    "vue-recaptcha": VueRecaptcha,
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_components.scss";
@import "../scss/_utilities.scss";
@import "../scss/_mixins.scss";

.container {
  height: calc(100vh - 115.2px);
  overflow-y: scroll;
  overflow-x: hidden;

  @include respond(laptop) {
    height: calc(100vh - 108px);
  }
  @include respond(small-laptop) {
    height: calc(100vh - 101.73px);
  }
  @include respond(tablet-land) {
    height: calc(100vh - 96px);
  }

  @include respond(tablet-port) {
    height: calc(100vh - 72px);
  }
}
.hero {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 5rem 15rem 0 15rem;

  @include respond(laptop) {
    margin: 4rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 3rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 1rem 8rem 0 8rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond(phone-land) {
    position: relative;
    margin: 8rem 3rem 0 3rem;
  }
  &__headline_2 {
    margin: 0 auto;
    & > h1 {
      font-family: "Raleway";
      font-size: 4.8rem;
      font-weight: 700;
      opacity: 0.8;
      text-align: center;

      @include respond(desktop) {
        font-size: 4.8rem;
      }

      @include respond(laptop) {
        font-size: 4.5rem;
      }

      @include respond(small-laptop) {
        font-size: 4rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }

  &__headline {
    & > h1 {
      font-family: "Raleway";
      font-size: 8rem;
      font-weight: bold;
      text-align: center;
      @include respond(desktop) {
        font-size: 8rem;
      }

      @include respond(laptop) {
        font-size: 7rem;
      }

      @include respond(small-laptop) {
        font-size: 6rem;
      }

      @include respond(tablet-land) {
        font-size: 5rem;
      }

      @include respond(phone-land) {
        font-size: 4rem;
      }
    }
  }

  &__headline_phoneNo {
    margin-top: 10rem;
    @include respond(desktop) {
      margin-top: 10rem;
    }

    @include respond(laptop) {
      margin-top: 9rem;
    }

    @include respond(small-laptop) {
      margin-top: 8rem;
    }

    @include respond(tablet-land) {
      margin-top: 7rem;
    }

    @include respond(phone-land) {
      margin-top: 7rem;
    }
    & > h1 {
      font-family: "Open Sans";
      font-size: 6.4rem;
      font-weight: bold;
      text-align: center;
      color: $color-red;

      @include respond(desktop) {
        font-size: 6.4rem;
      }

      @include respond(laptop) {
        font-size: 6rem;
      }

      @include respond(small-laptop) {
        font-size: 5.5rem;
      }

      @include respond(tablet-land) {
        font-size: 5rem;
      }

      @include respond(phone-land) {
        font-size: 4rem;
      }
    }
  }

  &__headline_email {
    margin: 0 auto;
    & > h1 {
      font-family: "Open Sans";
      font-size: 6.4rem;
      font-weight: bold;
      text-align: center;
      color: $color-gray-4;

      @include respond(desktop) {
        font-size: 6.4rem;
      }

      @include respond(laptop) {
        font-size: 6rem;
      }

      @include respond(small-laptop) {
        font-size: 5.5rem;
      }

      @include respond(tablet-land) {
        font-size: 5rem;
      }

      @include respond(phone-land) {
        font-size: 4rem;
      }
    }
  }

  &__text {
    margin-bottom: -5rem;
    margin-left: -10rem;

    @include respond(laptop) {
      margin-left: -9rem;
    }

    @include respond(small-laptop) {
      margin-left: -12rem;
    }

    @include respond(tablet-land) {
      margin-left: -14rem;
    }

    @include respond(tablet-port) {
      margin-left: 0;
      margin-top: 3rem;
    }

    & > p {
      opacity: 0.6;
      font-family: "Open Sans";
      font-size: 2.4rem;
      line-height: 1.42;

      @include respond(desktop) {
        font-size: 2.3rem;
      }

      @include respond(laptop) {
        font-size: 2.1rem;
      }

      @include respond(small-laptop) {
        font-size: 2rem;
      }

      @include respond(tablet-land) {
        font-size: 1.8rem;
      }

      @include respond(phone-land) {
        font-size: 1.6rem;
      }
    }
  }

  &__btn-container {
    margin-left: auto;
    margin-right: -15.5rem;
    transform: rotate(-90deg);

    @include respond(laptop) {
      margin-right: -12.5;
    }

    @include respond(phone-land) {
      margin-right: -10rem;
      margin-top: 15rem;
    }
  }

  &__animation {
    margin-top: 5rem;

    @include respond(desktop) {
      margin-top: 4rem;
    }

    @include respond(laptop) {
      margin-top: 3rem;
    }

    @include respond(small-laptop) {
      margin-top: 2rem;
    }

    @include respond(tablet-land) {
      margin-top: 2rem;
    }

    @include respond(phone-land) {
    }
  }
}
.formSection {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 8rem 15rem 0 15rem;

  @include respond(laptop) {
    margin: 20rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 20rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 15rem 0rem 0 0rem;
  }
  @include respond(phone-land) {
    margin: 7rem 0rem 0 0rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__headline_form {
    & > h1 {
      font-family: "Open Sans";
      font-size: 4.8rem;
      font-weight: bold;
      text-align: center;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 4.8rem;
      }

      @include respond(laptop) {
        font-size: 4.5rem;
      }

      @include respond(small-laptop) {
        font-size: 4rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }
}
.footer-section {
  scroll-snap-align: center;
}

.vue-form {
  font-size: 16px;
  padding: 0px 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 65%;
  @include respond(desktop) {
    font-size: 2rem;
    width: 65%;
  }

  @include respond(laptop) {
    font-size: 2rem;
    width: 65%;
  }

  @include respond(small-laptop) {
    font-size: 2rem;
    width: 65%;
  }

  @include respond(tablet-land) {
    font-size: 2rem;
    width: 75%;
  }

  @include respond(phone-land) {
    margin-top: 0rem;
    font-size: 2rem;
    width: 85%;
  }
}
.vue-form fieldset {
  margin: 24px 0 0 0;
}
.vue-form legend {
  padding-bottom: 10px;
}
.vue-form div {
  position: relative;
  margin: 20px 0;
}
.vue-form h4,
.vue-form .label {
  font-family: "Raleway";
  font-size: 3.2rem;
  font-weight: bold;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 10px;

  @include respond(desktop) {
    font-size: 3rem;
  }

  @include respond(laptop) {
    font-size: 2rem;
  }

  @include respond(small-laptop) {
    font-size: 2rem;
  }

  @include respond(tablet-land) {
    font-size: 2rem;
  }

  @include respond(phone-land) {
    font-size: 2rem;
  }
}
.vue-form .label {
  display: block;
}
.vue-form input,
.vue-form textarea,
.vue-form label {
  color: #30492d;
}
.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea,
.vue-form legend {
  display: block;
  width: 100%;
  appearance: none;
}
.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea {
  padding: 12px;

  font-family: "Open Sans";
  font-size: 2.5rem;
  border: none;
  background-color: #f0f0f0;

  @include respond(desktop) {
    font-size: 2.5rem;
  }

  @include respond(laptop) {
    font-size: 2.5rem;
  }

  @include respond(small-laptop) {
    font-size: 2.3rem;
  }

  @include respond(tablet-land) {
    font-size: 2rem;
  }

  @include respond(phone-land) {
    font-size: 1.8rem;
  }
}
.vue-form input[type="text"]:focus,
.vue-form input[type="email"]:focus,
.vue-form textarea:focus {
  outline: none;
}
.vue-form textarea {
  min-height: 220px;
  resize: none;
  overflow: auto;
}
.vue-form input[type="submit"] {
  border: none;
  background: #4e3ee0;
  padding: 20px 20px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  cursor: pointer;
  font-family: "Raleway";
  font-size: 2rem;
  font-weight: bold;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  width: 100%;
}
.no-touch .vue-form input[type="submit"]:hover {
  background: #42a2e1;
}

.vue-form input[type="submit"]:active {
  transform: scale(0.9);
}
.vue-form .error-message {
  height: 35px;
  margin: 0px;
}
.vue-form .error-message p {
  background: #e94b35;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 16px;
}
.vue-form .error {
  border-color: #e94b35 !important;
}
.vue-form .counter {
  background-color: #ecf0f1;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 10px;
  padding: 4px;
}
.fieldset {
  border: 0px;
}
.weNeedYourEmail {
  font-family: "Open Sans";
  font-size: 2rem;
  color: #e73252;
  opacity: 0.8;

  @include respond(desktop) {
    font-size: 2rem;
  }

  @include respond(laptop) {
    font-size: 1.8rem;
  }

  @include respond(small-laptop) {
    font-size: 1.8rem;
  }

  @include respond(tablet-land) {
    font-size: 1.6rem;
  }

  @include respond(phone-land) {
    font-size: 1.6rem;
  }
}

.offersSection {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 30rem 15rem 0 15rem;

  @include respond(laptop) {
    margin: 30rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 20rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 15rem 0rem 0 0rem;
  }
  @include respond(phone-land) {
    margin: 7rem 0rem 0 0rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__headline_likeUs {
    & > h1 {
      font-family: "Open Sans";
      font-size: 8rem;
      font-weight: bold;
      text-align: center;

      @include respond(desktop) {
        font-size: 8rem;
      }

      @include respond(laptop) {
        font-size: 8rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(phone-land) {
        font-size: 4.5rem;
      }
    }
  }
  &__headline_Team {
    & > h1 {
      font-family: "Open Sans";
      font-size: 6.4rem;
      font-weight: 600;
      text-align: center;

      @include respond(desktop) {
        font-size: 6.4rem;
      }

      @include respond(laptop) {
        font-size: 6.4rem;
      }

      @include respond(small-laptop) {
        font-size: 5.2rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }
  &__see-all {
    display: flex;
    justify-content: center;
    margin: 8rem auto 40rem auto;

    @include respond(tablet-port) {
      margin: 8rem auto 20rem auto;
      width: 60rem;
    }
    @include respond(phone-land) {
      margin: 8rem auto 15rem auto;
      width: 50rem;
    }
    @include respond(phone) {
      margin: 8rem auto 15rem auto;
      width: 40rem;
    }
  }
}
.emailLblErrorClass {
  color: #e73252 !important;
}
.submittedClass {
  outline: none;
  background: #4bb543 !important;
  cursor: not-allowed !important;
}
</style>
